.Popup_Box {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 40px 122px;
    background-color: #ffffff;
    border-radius: 8px;
    gap: 20px;
  }
  
  .Popup_Text {
    width: auto;
    height: auto;
    margin: auto;
  }
  
  .Popup_Text h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #363636;
    margin-bottom: 0px;
  }
  
  .Popup_Text p {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: #363636;
  }
  
  .Popup_Button {
    width: 100%;
    height: auto;
    margin:10px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
 
  }
  

  
  .Popup_Button .cancelBtn {
    width: 160px;
    height: auto;
    padding: 14px 52px;
    border-radius: 4px;
    background-color: #0a3b66;
    border: 1px solid #0a3b66;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    
  }

  .Popup_Button .loginBtn {
    width: 190px;
    height: auto;
    padding: 14px 52px;
    border-radius: 4px;
    color:#0a3b66;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    outline: 0;
    cursor: pointer;
    
  }
