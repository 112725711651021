.employeeDetails {
  height: 100vh;
  width: 100%;
}

.employeeDetails .one {
  height: 5vh;
  margin: 2px auto;
  width: 98%;
  display: flex;
  align-items: center;
}

.employeeDetails .one h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: left;
}

.employeeDetailsSection {
  display: flex;
  height: 80vh;
  position: relative;
}


.DetailsOfEmployeeLeft {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.DetailsOfEmployeeLeft::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.DetailsOfEmployeeLeft::-webkit-scrollbar-track {
  background: transparent;
}

.DetailsOfEmployeeLeft::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.listOfEmployeeRight {
  width: 35%;
  height: 100%;
  border-left: 1px solid #d9d9d9;
}

.loadMore_container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadMoreBtn {
  background-color: var(--btn-color);
  border: 0;
  outline: 0;
  width: 160px;
  height: 48px;

  border-radius: 4px;
  cursor: pointer;
}

.loadMoreBtn span {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: var(--secondary-color);
}

.Employees_Container {
  width: 96%;
  height: calc(100% - 70px);
}

.Employees_Details {
  width: 100%;
  height: 96%;
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.Employees_Data {
  width: calc(100% - 481px);
  height: 100%;
  border-right: 1px solid #d9d9d9;
}

.Main_Data {
  width: 100%;
  height: 100%;
}

.Employees_Whole_Data,
.Profile_Details {
  width: 93%;
  height: auto;
  margin: 0 auto;
}

.Employees_Whole_Data {
  margin-bottom: 30px !important;
}

.Profile_Details {
  width: 93% !important;
  margin: auto !important;

}

.Employees_Data_Heading {
  width: 100%;
  height: 70px;
  display: flex;
}

.Heading_Panel {
  width: calc(100% - 350px);
  height: 100%;
  display: flex;
}

.Heading_Panel h1 {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  margin-left: 0;
}
.headingInner{
  color: #2e2e2e;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  margin-left: 0;
}
.Heading_Panel_A {
  width: 350px;
  height: 100%;
  display: flex;
}

.Emp_Action_Button {
  width: auto;
  height: 100%;
  margin: auto;
  margin-right: 20px;
  display: flex;
}

.Emp_Action_Button p {
  color: #00a912;
  font-weight: 600;
  font-size: 16px;
  margin: auto;
  padding: 10px;
  cursor: pointer;
}

.Emp_Action_Button p span {
  color: #eb0100;
}

.Emp_Action_Button p strong {
  color: #ebb700;
}

.containerEmpDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* .Profile_Data {
  width: 500px;
}

.profilePic {
  width: 100%;
  height: 100%;
 
  border-radius: 4px;
  position: relative;
}

.profilePic img {
  height: 100%;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
} */
.Profile_Data {
  width: 500px;
  position: relative; 
}

.profilePic {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: relative;
}

.profilePic img {
  height: 100%;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

.logDetails {
  position: absolute;
  bottom: -22px;
  left: 50%;  
  transform: translateX(-50%); 
  background-color: var(--btn-color);
  border: 0;
  outline: 0;
  width: 160px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logDetails span {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: var(--secondary-color);
}

.Profile_Container {
  width: 60%;
  height: auto;
  margin: auto 20px !important;
  margin-top: 10px;
  /* background-color: #00a912; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.AccountStatus {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: -20px;
}

.AccountStatus p {
  color: #484848;
  font-weight: 500;
  font-size: 14px;
  margin: auto;
  margin-left: 0;
  color: red;
}

.AccountStatus a {
  color: #09355c;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  margin-left: 0;
  text-decoration: underline;
  cursor: pointer;
}

.Profile_Content {
  width: 100%;
  height: 31px;
  display: flex;
}

.Profile_Content_Heading {
  width: 150px;

  height: auto;
  margin: auto;
}

.Profile_Content_Heading p {
  color: #484848;
  font-weight: 500;
  font-size: 14px;
  margin: auto;
}

.T11 {
  width: 10px;
  height: auto;
  margin: auto;
}

.T11 p {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
}

.Profile_Content_Data {
  width: calc(100% - 220px);
  height: auto;
  margin: auto;
}

.Profile_Content_Data p {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
}

.Profile_Content_Data p a {
  color: #09355c;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
}

.All_Employees_List {
  height: 100%;
  width: 100%;
}

.EmployeeSearch {
  width: 100%;
  height: 100px;
  display: flex;
  padding: 0 10px;
  /* background-color: #003261; */
}

.SearchForm {
  width: 100%;
  /* height: 48px; */
  margin: auto;
  display: flex;
}

.Search_Input_Data {
  /* width: calc(100% - 72px); */
  width: 100%;
  height: calc(100% - 2px);
  /* border: 1px solid #d9d9d9; */
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InputData {
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
}

.InputData ::placeholder {
  color: #d9d9d9;
}

.InputData input[type="text"] {
  width: 100%;
  padding: 10px 16px;
  margin: auto;
  font-size: 14px;
  color: #363636;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: text;
}

.InputData input:focus {
  outline: none;
}

.Input_Data_Button {
  height: 100%;

  position: relative;
}

.model_filter {
  position: absolute;
  top: 49px;
  right: -35px;
  width: 324px;
  /* height: 356px; */
  gap: 0px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 1px 6px 1px #0019173d;
  background: #ffffff;
}

.model_filter .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.model_filter .heading h3 {
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}

.model_filter .heading h3:first-child {
  color: black !important;
  font-size: 16px;
}

.model_filter .heading h3:last-child {
  color: #c0c0c0;
  font-size: 18px;
}

.model_filter .filterBasedOn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.model_filter .filterBasedOn .name {
  flex: 45%;
}

.model_filter .filterBasedOn .nameOfChoosen {
  flex: 45%;
  height: 200px;
  overflow-y: scroll;
}

.nameOfChoosen::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.nameOfChoosen::-webkit-scrollbar-track {
  background: transparent;
}

.nameOfChoosen::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.model_filter .filterBasedOn .nameOfChoosen .checkbox_container {
  /* padding: 10px 0; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
}

.model_filter .filterBasedOn .borderCenter {
  flex: 1%;
  height: 180px;
  border-radius: 1px;
  margin: 0 10px;
  background-color: #e4e4e4;
  align-self: center;
}

.model_filter .filterBasedOn .name {
  flex: 50%;
}

.model_filter .filterBasedOn .name .nameOfThat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 129px;
  height: 35px;
  padding: 20px 0;
  border-radius: 20px;
  margin: 20px 0;
  cursor: pointer;
}

.model_filter .filterBasedOn .name .skyBlue {
  background: #f2f2f2;
}

.model_filter .filterBasedOn .name .nameOfThat .model_filter .filterBasedOn .name .nameOfThat p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #454545;
}

.checkbox_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkBox_filter {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #ababab;
}

.filterBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterBtn button {
  width: 180px !important;
  height: 48px !important;
  background: #09355c !important;
  color: white !important;
  border-radius: 4px !important;
  margin: auto !important;
}

.filterBtn button span {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
}

.iconsCheckBox {
  width: 20%;
}

.nameDesig {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2px;
  /* background-color: #003261; */
}

.checkbox_container p {
  /* margin: 0 10px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;

  color: #454545;
}

.Input_Data_Button button {
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.Input_Data_Button button img {
  width: 26px;
  margin: auto;
  cursor: pointer;
}

.Search_Filter_Data {
  width: 70px;
  height: 100%;
  display: flex;
}

.Search_Filter_Data img {
  width: 35px;
  height: 35px;
  margin: auto;
  margin-right: 20px;
  cursor: pointer;
}

.Employees_Lists {
  width: 100%;
  padding: 0 10px;
  height: 85%;
  overflow-y: auto;
}

.Employees_Lists::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.Employees_Lists::-webkit-scrollbar-track {
  background: transparent;
}

.Employees_Lists::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.Employee_List_Data {
  width: 99%;
  height: auto !important;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  cursor: pointer;
}

.status_employee {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle_status1 {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #eb0100;
}

.circle_status2 {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #ebb700;
}

.Employee_List_Data[tabindex]:focus {
  background-color: #f1f1f1;
}

.Employee_List_Data[tabindex]:focus h1 {
  color: #09355c;
  font-weight: 800;
}

.Employee_List_Data[tabindex]:focus p strong {
  color: #2e2e2e;
  font-weight: 600;
}

.Employee_List_Data[tabindex]:focus p {
  color: #2e2e2e;
  font-weight: 600;
}

.Employee_Picture {
  width: 120px;
  height: 100%;
  display: flex;
}

.Employee_Picture img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
}

.Employee_Content {
  width: calc(100% - 120px);
  height: auto;
  margin: auto;
}

.Employee_Content h1 {
  color: #2e2e2e;
  font-weight: 700;
  font-size: 16px;
  margin: auto;
}

.Employee_Content p {
  color: #787878;
  font-weight: 400;
  font-size: 12px;
  margin: auto;
}

.Employee_Content p strong {
  color: #2e2e2e;
  font-weight: 500;
  font-size: 14px;
  margin: auto;
}

/* .Employee_Status {
    width: 70px;
    height: 100%; 
    display: flex; 
}  */
.Button_Space {
  width: 100%;
  height: 30px;
}

.Password_Confirmation_Block {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.Password_Confirmation_Container {
  width: 568px;
  height: 100%;
}

.Panel_A {
  width: calc(50% - 284px);
  height: 100%;
}

.Panel_B {
  width: 100%;
  height: calc(50% - 164px);
}

.PopUp {
  width: 100%;
  height: 328px;
  border-radius: 8px;
  display: flex;
  background-color: white;
}

.Password_Confirmation {
  width: 568px;
  height: 328px;
  margin: auto;
  display: flex;
  border-radius: 8px;
  background-color: white;
}

.Confirm_Password {
  width: 86%;
  height: 76%;
  margin: auto;
}

.Password_Heading {
  width: 100%;
  height: 40px;
  display: flex;
}

.Password_Heading h1 {
  color: #363636;
  font-weight: 600;
  font-size: 24px;
  margin: auto;
}

.Confirmation_Content {
  width: 100%;
  height: 20px;
  display: flex;
}

.Confirmation_Content p {
  color: #363636;
  font-weight: 400;
  font-size: 14px;
  margin: auto;
}

.Password_InputField {
  width: 70%;
  height: calc(100% - 150px);
  display: flex;
  margin: auto;
}

.Password {
  width: 100%;
  height: 56px;
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.Password_Input {
  width: 85%;
  height: 56px;
}

.EyeButton {
  width: 15%;
  height: 56px;
  display: flex;
}

.EyeButton p {
  margin: auto;
  color: #363636;
  font-size: 35px;
  cursor: pointer;
}

.Password_Input input[type="password"] {
  width: 100%;
  padding: 16px 16px;
  margin: auto;
  font-size: 18px;
  color: #363636;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: text;
}

.Password_Input input[type="text"] {
  width: 100%;
  padding: 16px 16px;
  margin: auto;
  font-size: 18px;
  color: #363636;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: text;
}

.Password_Input input:focus {
  outline: none;
}

.VerifyButton {
  width: 100%;
  height: 70px;
  display: flex;
}

.AlertMsg {
  width: 100%;
  height: 20px;
  display: flex;
}

.AlertMsg p {
  color: red;
  margin: auto;
  font-weight: 600;
  font-size: 12px;
}

.VerifyButton button {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  border: none;
  background-color: #0a3b66;
  border-radius: 4px;
  padding: 10px 40px;
  cursor: pointer;
}

.EditEmployee_Details {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.GetEmployeeDataForEdit {
  width: 74%;
  height: 74%;
  margin: auto;
  display: flex;
  background-color: white;
  border-radius: 8px;
}

.FormContainer {
  width: 90%;
  height: 90%;
  margin: auto;
}

.Form_Heading {
  width: 100%;
  height: 40px;
  display: flex;
}

.Form_Heading_Panel {
  width: 50%;
  height: 100%;
}

.Form_Heading_Panel h1 {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 18px;
  margin: auto;
}

.RAT_Data {
  width: 110px;
  height: auto;
  margin: auto;
  margin-top: 0;
  margin-right: 5%;
  display: flex;
}

.Instruction_About_RAP {
  width: 30px;
  height: auto;
  margin: auto;
  display: flex;
}

.Instruction_About_RAP img {
  width: auto;
  margin: auto;
  cursor: pointer;
}

.Select_RAP {
  width: calc(100% - 30px);
  height: 100%;
  display: flex;
}

.Select_RAP p {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 18px;
  margin: auto;
}

input.larger {
  width: 20px;
  height: 20px;
  color: #08233c;
  cursor: pointer;
  margin: auto;
  background-color: #08233c;
}

.Form_Details {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: scroll;
}

.UpdateEmployeesData {
  width: 100%;
  height: auto;
}

.Employees_Details_For_Update {
  width: 100%;
  height: 56px;
  display: flex;
  margin-top: 6px;
}

.Space_And_Data {
  width: 100%;
  height: 40px;
}

.Input_Field_Panel_D {
  width: 28%;
  height: 100%;
  margin: auto;
  margin-left: 0;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.Input_Field_Panel_B {
  width: 28%;
  height: 100%;
  margin: auto;
  display: flex;
}

.Input_Field_Panel_C {
  width: 28%;
  height: 100%;
  margin: auto;
  margin-right: 0;
}

.InputField {
  width: calc(100% - 2px);
  height: 56px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.InputFielt {
  width: calc(100% - 2px);
  height: 56px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.InputFielt p {
  color: #787878;
  font-weight: 700;
  font-size: 16px;
  margin: auto;
  margin-left: 5px;
}

.Input {
  width: 94%;
  height: 60%;
  margin: auto;
  position: relative;
  padding-top: 12px;
}

.Input_AA {
  width: 94%;
  height: 60%;
  margin: auto;
  position: relative;
  padding-top: 12px;
  cursor: pointer;
}

.Input_AA h1 {
  width: 100px;
  color: #787878;
  margin-top: -22px;
  font-weight: 600;
  font-size: 14px;
  background-color: white;
  z-index: 100;
}

.inputText {
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.floating_label {
  position: absolute;
  pointer-events: none;
  left: 0px;
  padding: 0px 5px;
  top: 18px;
  color: #d9d9d9;
  transition: 0.2s ease all;
  font-size: 17px;
  font-weight: 500;
}

.Input p {
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  cursor: pointer;
}

input:focus~.floating_label,
input:not(:focus):valid~.floating_label {
  color: #787878;
  top: -10px;
  font-weight: 600;
  font-size: 14px;
  background-color: white;
  padding: 0px 5px;
}

input[type="text"],
[type="email"],
[type="number"],
[type="date"] {
  width: 99%;
  padding: auto;
  font-size: 18px;
  color: #363636;
  font-weight: 700;
  display: inline-block;
  border: none;
  box-sizing: border-box;
  cursor: text;
  background-color: white;
}

input[type="file"] {
  width: 100%;
  color: transparent;
}

input[type="file"]::file-selector-button {
  display: none;
}

input[type="file"]::-webkit-file-upload-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: -100%;
  color: white;
}

input[type="file"]::-ms-browse {
  display: none;
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  /* background: url('src/assets/Images/SVG_Images/Calendar.svg') no-repeat; */
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}

input[type="time"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type="time"]:focus::-webkit-datetime-edit {
  color: black !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  /* background: url('src/assets/Images/SVG_Images/ClockIcon.svg') no-repeat; */
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}

input:focus {
  outline: none;
}

input.ng-touched.ng-invalid {
  border-bottom: 1px solid red;
}

input.ng-touched.ng-valid {
  border-bottom: 1px solid #1a2d4a;
}

select.ng-touched.ng-invalid {
  border-bottom: 1px solid red;
}

select.ng-touched.ng-valid {
  border-bottom: 1px solid #104372;
}

.Fields_B {
  width: 94%;
  height: 60%;
  margin: auto;
  position: relative;
  padding-top: 12px;
}

.floating-input,
.floating-select {
  font-size: 16px;
  color: #363636;
  font-weight: 700;
  padding: 4px 2px;
  display: block;
  width: 100%;
  height: 26px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
}

label {
  color: #d9d9d9;
  font-size: 17px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 18px;
  font-weight: 500;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label {
  color: #787878;
  top: -10px;
  font-weight: 600;
  font-size: 14px;
  background-color: white;
  padding: 0px 5px;
}

.floating-select:focus~label,
.floating-select:not([value=""]):valid~label {
  color: #787878;
  top: -10px;
  font-weight: 600;
  font-size: 14px;
  background-color: white;
  padding: 0px 5px;
}

.Upload_Icon {
  width: 98.5%;
  height: 100%;
  display: flex;
}

.Upload_Icon img {
  width: auto;
  margin: auto;
  margin-right: 0;
  margin-top: 0;
  cursor: pointer;
}

.IPProofPic {
  width: 100%;
  height: 200px;
  display: flex;
}

.ID_Photo {
  width: 28%;
  height: 100%;
  margin: auto;
  margin-right: 0;
}

.ID_Photo img {
  width: 100%;
  height: 100%;
}

.Submit_Button {
  width: 44%;
  height: 56px;
  margin: auto;
  display: flex;
}

.Submit_Button p {
  color: #0a3b66;
  font-size: 16px;
  margin: auto;
  font-weight: 600;
  cursor: pointer;
}

.Submit_Button button {
  width: 160px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  border: none;
  background-color: #0a3b66;
  border-radius: 4px;
  padding: 10px 40px;
  cursor: pointer;
}

.Loader_Container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.Loader_Container img {
  width: 80px;
  margin: auto;
}

.SuccessMessage_Container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.SuccessBox {
  width: 307px;
  height: 276px;
  margin: auto;
  display: flex;
  background-color: white;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
}

.Success_Content {
  width: 100%;
  height: auto;
  margin: auto;
}

.Success_Icon {
  width: 100%;
  height: 90px;
  display: flex;
}

.Success_Icon img {
  width: 80px;
  margin: auto;
}

.Success_Heading {
  width: 100%;
  height: 50px;
  display: flex;
}

.Success_Heading p {
  color: #484848;
  font-weight: 600;
  font-size: 18px;
  margin: auto;
}

.Employees_Image_Update_Container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.EmployeesImageUpdate {
  width: 550px;
  height: 75%;
  margin: auto;
  border-radius: 16px;
  display: flex;
  background-color: white;
}

.Image_Update_Section {
  width: 82%;
  height: 75%;
  margin: auto;
}

.Section_1 {
  width: 100%;
  height: 70%;
  margin: auto;
}

.Info_Images {
  width: 100%;
  height: 50%;
  display: flex;
}

.Image_Section {
  width: 50%;
  height: 100%;
  display: flex;
}

.Image_Section img {
  width: auto;
  margin: auto;
}

.Info_Content {
  width: 100%;
  height: 50%;
}

.Info_Content P {
  color: #565656;
  font-size: 15px;
  font-weight: 500;
}

.Camera_Container {
  width: 100%;
  height: 75%;
  position: relative;
}

.Space_II {
  width: 100%;
  height: 8px;
}

.Camera_Image {
  width: 320px;
  height: 320px;
  margin: auto;
  border-radius: 50%;
}

.Camera_Image img {
  width: 320px;
  height: 320px;
  margin: auto;
  border-radius: 50%;
}

.Camera {
  width: 375px;
  height: 300px;
  margin: auto;
  border-radius: 50%;
}

.Overlay {
  height: 300px;
  width: 452px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Overlay img {
  width: 100%;
  height: 100%;
}

.Camera_Content {
  width: 100%;
  height: 25%;
  display: flex;
}

.Camera_Content p {
  color: #565656;
  text-align: center;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 500;
  margin: auto;
}

.Section_2 {
  width: 100%;
  height: 30%;
  display: flex;
  margin: auto;
}

.Section_2 button {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  border: none;
  background-color: #0a3b66;
  border-radius: 4px;
  padding: 10px 40px;
  cursor: pointer;
}

.Section_2 button:hover {
  background-color: #003261;
}
.blur {
  position: relative;
}

.blur > *:not(.errorMessage) {
  filter: blur(1px);
  pointer-events: none;
  user-select: none;
}

.errorMessage {
  position: absolute;
  z-index: 99;
  left: 25%;
  top: 5%;
  background: #EB0100;
  border-radius: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.errorMessage p {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  color: white;
}

@media screen and (max-width: 1550px) {
  /* .logDetails {
    position: absolute;
    bottom: -22px;
    right: 25%;
    background-color: var(--btn-color);
    border: 0;
    outline: 0;
    width: 120px;
    height: 48px;
    border-radius: 4px;
    cursor: pointer;
  } */

  .logDetails span {
    font-size: 12px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: var(--secondary-color);
  }
}

@media screen and (max-width: 1279px) {
  .profilePic {
    width: 200px;
    height: 220px;
    object-fit: contain;
    border-radius: 4px;
    position: relative;
  }

  /* .logDetails {
    position: absolute;
    bottom: -22px;
    right: 20%;
    background-color: var(--btn-color);
    border: 0;
    outline: 0;
    width: 120px;
    height: 48px;
    border-radius: 4px;
    cursor: pointer;
  } */

  .logDetails span {
    font-size: 12px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: var(--secondary-color);
  }

  .profilePic img {
    height: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1279px) {
  app-header {
    height: 75px;
  }

  main {
    top: 75px;
  }

  app-side-nav {
    left: 0;
    height: auto;
    width: 250px;
  }

  section {
    left: 250px;
    width: calc(100% - 250px);
  }

  .Section {
    height: calc(100vh - 75px);
  }

  .Top_Heading {
    width: 96%;
    height: 55px;
  }

  .Top_Heading h1 {
    font-size: 16px;
    line-height: 2.4;
  }

  .Employees_Container {
    width: 96%;
    height: calc(100% - 55px);
  }

  .Employees_Data {
    width: calc(100% - 301px);
  }

  .Employees_Data_Heading {
    width: 100%;
    height: 50px;
  }

  .Emp_Action_Button p {
    font-size: 14px;
    padding: 10px;
  }

  .Profile_Data {
    height: 220px;
  }

  .Profile_Images {
    width: 170px;
    margin-right: 20px;
  }

  .Log_Details {
    width: 140px;
    height: 36px;
    margin-top: -18px;
  }

  .Log_Details p {
    font-size: 14px;
  }

  .Profile_Container {
    width: calc(100% - 190px);
    margin: auto;
    margin-top: 20px;
  }

  .Profile_Content {
    width: 100%;
    height: auto;
  }

  .Profile_Content_Heading {
    width: 140px;
  }

  .Profile_Content_Heading p {
    font-size: 13px;
    line-height: 1.5;
  }

  .T11 p {
    font-size: 13px;
    line-height: 1.5;
  }

  .Profile_Content_Data {
    width: calc(100% - 150px);
  }

  .Profile_Content_Data p {
    font-size: 13px;
    line-height: 1.5;
  }

  .Profile_Content_Data p a {
    font-size: 11px;
    padding: 4px 6px;
  }

  /* .Profile_Details {
    margin-top: 10px !important;
    width: 100%;
    height: calc(100% - 320px);
    overflow-y: scroll;
  } */

  .EmployeeSearch {
    width: 100%;
    height: 70px;
  }

  .SearchForm {
    width: 100%;
    height: 40px;
  }

  .Employees_Lists {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: scroll;
  }

  .Employee_List_Data {
    height: 100px;
  }

  .Employee_Picture {
    width: 90px;
    height: 100%;
    display: flex;
  }

  .Employee_Picture img {
    width: 65px;
    height: 65px;
  }

  .Employee_Content {
    width: calc(100% - 90px);
  }

  .Employee_Content h1 {
    font-size: 14px;
  }

  .Employee_Content p {
    font-size: 12px;
    margin: auto;
  }

  .Employee_Status {
    width: 70px;
    height: 100%;
    display: flex;
  }

  .Form_Heading_Panel h1 {
    font-size: 16px;
  }

  .EmployeesImageUpdate {
    width: 550px;
    height: 75%;
  }

  .Space {
    width: 100%;
    height: 10px;
  }

  .Info_Content P {
    color: #565656;
    font-size: 14px;
    font-weight: 500;
  }

  .Section_2 button {
    margin: auto;
    margin-bottom: 0px;
  }

  .Camera_Container {
    width: 100%;
    height: 87%;
  }

  .Camera {
    width: 375px;
    height: 300px;
  }

  .Overlay {
    height: 300px;
    width: 452px;
  }

  .Camera_Content {
    height: 13%;
    margin-top: 10%;
  }

  .Camera_Content p {
    margin: auto;
    margin-bottom: 0;
  }
}

.hide-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* WebKit (Chrome, Safari) */
}

@media screen and (max-width: 1366px){
  .employeeDetailsSection {
    margin: 2px auto;
    width: 98%;
    height: 75vh;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    
  }
}
