.skeleton-container {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  padding: 10px;
}

.skeleton-container::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.skeleton-container::-webkit-scrollbar-track {
  background: transparent;
}

.skeleton-container::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.upperSkeleton,
.LowerupperSkeleton {
  flex: 50%;
}
.upperSkeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upperSkeleton .leftEmpInfo,
.rightEmpInfo {
  flex: 50%;
}

.skeleton-profile-pic {
  width: 90%;
  height: 400px;
  margin: auto;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  border-radius: 10px;
  margin-bottom: 20px;
  animation: shimmer 1.5s infinite;
}
.skeleton-detailHight {
  width: 100%;
  height: 50px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  margin-bottom: 10px;
  animation: shimmer 1.5s infinite;
  border-radius: 10px;
}
.skeleton-detail {
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  margin-bottom: 10px;
  animation: shimmer 1.5s infinite;
  border-radius: 10px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
/* employeeList */

.skeleton-container-employee-list {
  width: 35%;
  height: 100%;
  border-left: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
}
.skeleton-container-employee-list::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.skeleton-container-employee-list::-webkit-scrollbar-track {
  background: transparent;
}

.skeleton-container-employee-list::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}
.skeleton-detail-employee-list {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  margin-bottom: 10px;
  animation: shimmer_employeeList 5s infinite;
  border-radius: 20px;
}

@keyframes shimmer_employeeList {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
