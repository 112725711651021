.dashboard {
  height: 100vh;
  width: 100%;

}

.dashboard .one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  margin: 2px auto;
  width: 98%;
}

.dashboard .one h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: left;
}

.dashboard .one p {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  text-align: left;
  color: #787878;
}
.dashboardUi{
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px;
}
.dashboardUi_left{
flex:6.25;
}
.dashboardUi_Right{
flex:3.25;

}
.dashboardUi_center{
  flex:0.1;
}
.dashboardChildUi{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

}
.upperSection{
flex:1;
}
.upperrest{
flex:4;
margin: 10px 0;
}
.lastOne{
flex:4;
}


/* .dashboardAttendance {
  overflow-y: auto !important;
}




.dashboardAttendance::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.dashboardAttendance::-webkit-scrollbar-track {
  background: transparent;
}

.dashboardAttendance::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
} */

/* Angular Css Copied */

.Dashboard_Data_Container {
  margin: 20px 0;
  height: 100%;
  width: 100%;
}

.Dashboard_Details_Tiles {
  width: 96%;
  height: 100%;
  margin: auto;

}

.Dashboard_Panel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding:20px 0;
}

.Best_Employee_of_the_year {
  width: 64%;
  height: 77vh;
}

.Best_Employee_Data {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #0000001f;
  border-radius: 8px;
}

.upperest_one {
  height: 100%;
  width: 100%;
  background: #09355c;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 1px 4px 0px #0000001f;

}

.upper_filter_dashboard p {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  color: #ffffff;
}



.lower_filter_dashboard {
  margin: 10px 0;
  display: flex;
  position: relative; 
}
.dateRangeSelect{
  position: absolute;
  top:-40px;
  right:2px;
}

.lower_filter_dashboard button {
  flex: 1;                   
  border: 0;
  outline: 0;
  padding: 10px;             
  background: #ffffff;
  border-radius: 5px;
  margin: 0 5px;            
  cursor: pointer;
 
}

.lower_filter_dashboard button:first-child {
  margin-left: 0;            /* Remove left margin for the first button */
}

.lower_filter_dashboard button:last-child {
  margin-right: 0;           /* Remove right margin for the last button */
}

.upper_second {
  height: 100%;
  width: 100%;
  display: flex;
}
.upper_second_left{
  flex:4;
}
.upper_second_Right{
  flex:6;
}
.upper_second__center{
  flex:0.13
}
.lower_One {
  height: 100%;
  width: 100%;
}

.Best_Employee_Data_Heading {
  width: 91%;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Switch_Panel_Heading {
  /* width: 80%; */
  height: 100%;
  display: flex;
}

.Switch_Panel {
  /* width: 20%; */
  height: 100%;
}

.Switch_Panel_Heading h1 {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 16px;
  margin: auto;
  margin-left: 0;
}

.Emp_Details_Heading {
  width: 91%;
  height: 40px;
  margin: auto;
  display: flex;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.06);
}

.Sr_No {
  width: 10%;
  height: 100%;
  display: flex;
}

.Sr_No p {
  color: #87888c;
  font-weight: 500;
  font-size: 13px;
  margin: auto;
}

.Emp_Name {
  width: 25%;
  height: 100%;
  display: flex;
}

.Emp_Name p {
  color: #87888c;
  font-weight: 500;
  font-size: 13px;
  margin: auto;
  margin-left: 0;
}

.ProgressBar {
  width: 45%;
  height: 100%;
  display: flex;
}

.ProgressBar p {
  color: #87888c;
  font-weight: 500;
  font-size: 13px;
  margin: auto;
  margin-left: 0;
}

.Percentage {
  width: 20%;
  height: 100%;
  display: flex;
}

.Percentage p {
  color: #87888c;
  font-weight: 500;
  font-size: 13px;
  margin: auto;
}

.Employee_List {
  width: 100%;
  height: calc(100% - 91px);
  margin: auto;
}

.Emp_Details_Heading_A {
  width: 91%;
  height: 19.6%;
  margin: auto;
  display: flex;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.06);
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.shimmer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* background: #f6f7f8; */
  background-image: linear-gradient(to right,
      #ffffff 0%,
      /* White at the start */
      #f0f0f0 50%,
      /* Light gray in the middle */
      #dcdcdc 100%
      /* Gray at the end */
    );
  /* background-repeat: no-repeat;
  background-size: 1000px 100%; */
  animation: shimmer 5s infinite linear;
}

.shimmer-item {
  width: 45%;
  max-height: 150px;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

@keyframes shimmerYear {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.shimmerYear {
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border-radius: 8px;
  /* background: #f6f7f8; */
  background-image: linear-gradient(to right,
      #ffffff 0%,
      /* White at the start */
      #f0f0f0 50%,
      /* Light gray in the middle */
      #dcdcdc 100%
      /* Gray at the end */
    );
  /* background-repeat: no-repeat;
    background-size: 1000px 100%; */
  animation: shimmerYear 5s infinite linear;
}

.shimmer-itemYear {
  width: 91%;
  max-height: 19.6%;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

@keyframes shimmerGraph {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.shimmerGraph {
  width: 100%;
  height: 90%;
  margin: auto;
  /* margin-bottom: 0; */
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  background-image: linear-gradient(to right,
      #ffffff 0%,
      #f0f0f0 50%,
      #dcdcdc 100%);

  animation: shimmerGraph 5s infinite linear;
}

.shimmer-itemGraph {
  width: 45%;
  max-height: 150px;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

@keyframes shimmerTodayAtt {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.shimmerTodayAtt {
  width: 100%;
  height: 100%;
  float: right;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(to right,
      #ffffff 0%,
      #f0f0f0 50%,
      #dcdcdc 100%);

  animation: shimmerTodayAtt 5s infinite linear;
}

.shimmer-itemTodayAtt {
  width: 45%;
  max-height: 150px;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.Sr_No_A {
  width: 10%;
  height: 100%;
  display: flex;
}

.Sr_No_A p {
  color: #5c5c5c;
  font-weight: 600;
  font-size: 13px;
  margin: auto;
}

.Emp_Name_A {
  width: 25%;
  height: 100%;
  display: flex;
}

.Emp_Name_A p {
  color: #5c5c5c;
  font-weight: 600;
  font-size: 13px;
  margin: auto;
  margin-left: 0;
  text-transform: capitalize;
}

.ProgressBar_A {
  width: 45%;
  height: 100%;
  display: flex;
}

.Progress_Bar {
  width: 92%;
  height: auto;
  display: flex;
  border-radius: 30px;
}

.Progress_Bar progress {
  width: 100%;
  height: 5px;
  margin: auto;
  border-radius: 30px;
}

.Progress_Bar progress {
  color: lightblue;
}

.Progress_Bar progress::-moz-progress-bar {
  background: lightblue;
}

.Progress_Bar progress::-webkit-progress-value {
  background: #0eff00;
  border-radius: 30px;
}

.Progress_Bar progress::-webkit-progress-bar {
  background: #f2f2f2;
  border-radius: 30px;
}

.Progress_Bar_1 {
  width: 92%;
  height: auto;
  display: flex;
  border-radius: 30px;
}

.Progress_Bar_1 progress {
  width: 100%;
  height: 5px;
  margin: auto;
  border-radius: 30px;
}

.Progress_Bar_1 progress {
  color: lightblue;
}

.Progress_Bar_1 progress::-moz-progress-bar {
  background: lightblue;
}

.Progress_Bar_1 progress::-webkit-progress-value {
  background: #a3f364;
  border-radius: 30px;
}

.Progress_Bar_1 progress::-webkit-progress-bar {
  background: #f2f2f2;
  border-radius: 30px;
}

.Progress_Bar_2 {
  width: 92%;
  height: auto;
  display: flex;
  border-radius: 30px;
}

.Progress_Bar_2 progress {
  width: 100%;
  height: 5px;
  margin: auto;
  border-radius: 30px;
}

.Progress_Bar_2 progress {
  color: lightblue;
}

.Progress_Bar_2 progress::-moz-progress-bar {
  background: lightblue;
}

.Progress_Bar_2 progress::-webkit-progress-value {
  background: #a3f364;
  border-radius: 30px;
}

.Progress_Bar_2 progress::-webkit-progress-bar {
  background: #f2f2f2;
  border-radius: 30px;
}

.Progress_Bar_3 {
  width: 92%;
  height: auto;
  display: flex;
  border-radius: 30px;
}

.Progress_Bar_3 progress {
  width: 100%;
  height: 5px;
  margin: auto;
  border-radius: 30px;
}

.Progress_Bar_3 progress {
  color: lightblue;
}

.Progress_Bar_3 progress::-moz-progress-bar {
  background: lightblue;
}

.Progress_Bar_3 progress::-webkit-progress-value {
  background: #ffe927;
  border-radius: 30px;
}

.Progress_Bar_3 progress::-webkit-progress-bar {
  background: #f2f2f2;
  border-radius: 30px;
}

.Progress_Bar_4 {
  width: 92%;
  height: auto;
  display: flex;
  border-radius: 30px;
}

.Progress_Bar_4 progress {
  width: 100%;
  height: 5px;
  margin: auto;
  border-radius: 30px;
}

.Progress_Bar_4 progress {
  color: lightblue;
}

.Progress_Bar_4 progress::-moz-progress-bar {
  background: lightblue;
}

.Progress_Bar_4 progress::-webkit-progress-value {
  background: #ffc803;
  border-radius: 30px;
}

.Progress_Bar_4 progress::-webkit-progress-bar {
  background: #f2f2f2;
  border-radius: 30px;
}

.Progress_Bar_5 {
  width: 92%;
  height: auto;
  display: flex;
  border-radius: 30px;
}

.Progress_Bar_5 progress {
  width: 100%;
  height: 5px;
  margin: auto;
  border-radius: 30px;
}

.Progress_Bar_5 progress {
  color: lightblue;
}

.Progress_Bar_5 progress::-moz-progress-bar {
  background: lightblue;
}

.Progress_Bar_5 progress::-webkit-progress-value {
  background: #ffa800;
  border-radius: 30px;
}

.Progress_Bar_5 progress::-webkit-progress-bar {
  background: #f2f2f2;
  border-radius: 30px;
}

.Percentage_A {
  width: 20%;
  height: 100%;
  display: flex;
}

.Percentage_Container {
  width: 55px;
  height: 26px;
  margin: auto;
  display: flex;
  background: rgba(24, 255, 47, 0.12);
  border: 0.5px solid #18ff2f;
  border-radius: 4px;
}

.Percentage_Container p {
  color: #404040;
  font-weight: 600;
  font-size: 10px;
  margin: auto;
}

.Percentage_Container_1 {
  width: 55px;
  height: 26px;
  margin: auto;
  display: flex;
  background: rgba(163, 243, 100, 0.12);
  border: 0.5px solid #a3f364;
  border-radius: 4px;
}

.Percentage_Container_1 p {
  color: #404040;
  font-weight: 600;
  font-size: 10px;
  margin: auto;
}

.Percentage_Container_2 {
  width: 55px;
  height: 26px;
  margin: auto;
  display: flex;
  background: rgba(163, 243, 100, 0.12);
  border: 0.5px solid #a3f364;
  border-radius: 4px;
}

.Percentage_Container_2 p {
  color: #404040;
  font-weight: 600;
  font-size: 10px;
  margin: auto;
}

.Percentage_Container_3 {
  width: 55px;
  height: 26px;
  margin: auto;
  display: flex;
  background: rgba(255, 233, 39, 0.12);
  border: 0.5px solid #ffe927;
  border-radius: 4px;
}

.Percentage_Container_3 p {
  color: #404040;
  font-weight: 600;
  font-size: 10px;
  margin: auto;
}

.Percentage_Container_4 {
  width: 55px;
  height: 26px;
  margin: auto;
  display: flex;
  background: rgba(255, 200, 3, 0.12);
  border: 0.5px solid #ffc803;
  border-radius: 4px;
}

.Percentage_Container_4 p {
  color: #404040;
  font-weight: 600;
  font-size: 10px;
  margin: auto;
}

.Percentage_Container_5 {
  width: 55px;
  height: 26px;
  margin: auto;
  display: flex;
  background: rgba(255, 168, 0, 0.12);
  border: 0.5px solid #ffa800;
  border-radius: 4px;
}

.Percentage_Container_5 p {
  color: #404040;
  font-weight: 600;
  font-size: 10px;
  margin: auto;
}

.Current_Date_Attendence {
  width: 100%;
  height: 100%;
  background: #eaf0f5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
padding: 10px;
}

.CurrentDate_Attendance_Heading {
  width: 100%;
  height: 50px;
}

.CurrentDate_Attendance_Heading h1 {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 16px;
  margin: auto;
  margin-left: 0;
}

.CurrentDate_Attendance_Heading h2 {
  color: #09355c;
  font-weight: 700;
  font-size: 20px;
  margin: auto;
  margin-left: 0;
}

.Circle_Graph {
  width: 100%;
}

.Attendence_CircleGraph {
  width:100%;
  height: 100%;
}

@keyframes progress {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.HalfCircle_Graph {
  --percentage: var(--value);
  --primary: #1c4374;
  --secondry: #f9f9f9;
  --size: 100%;
  width: var(--size);
  animation: progress 2s 0.5s forwards;
  aspect-ratio: 2 /1;
  margin: auto;
  margin-bottom: 0%;
  border-radius: 50% /100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.HalfCircle_Graph::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(from 0.75turn at 50% 100%,
      var(--primary) calc(var(--value) * 1% / 2),
      var(--secondry) 0);
  mask: radial-gradient(at 50% 100%, white 45%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 45%, #000 0);
  -webkit-mask-mode: alpha;
}

.HalfCircle_Graph::after {
  counter-reset: percentage var(--value);
}

.HalfCircle_Graph h1 {
  color: #565656;
  font-weight: 700;
  font-size: 30px;
  margin: auto;
  margin-bottom: 2px;
}

.Attendance_Content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
}

.Attendance_Content P {
  color: #87888c;
  font-weight: 600;
  font-size: 12px;
  margin: 50px auto;
  margin-top: 0;
}

.Punctuality_index_Graph {
  width: 100%;
  height:90%;
  border-radius: 8px;
}

.Punctuality_index_Graph_Heading {
  width: 93.5%;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Department_Specific_Attendance {
  width: 100%;
  background: #eaf0f5;
  border-radius: 8px;
  height: 100%;
  padding: 10px;
  box-shadow: 0px 1px 4px 0px #0000001f;

}

.Dashboard_lowerGraph {
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 4px 0px #0000001f;
  border-radius: 8px;


}

.Department_Specific_Data {
  width: 100%;
  height: calc(100% - 80px);
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-y: auto;
}

.Department_Specific_Data::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.Department_Specific_Data::-webkit-scrollbar-track {
  background: transparent;
}

.Department_Specific_Data::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.graphShowing {
  width: 100%;
  height: 250px;
  max-height: 250px;
  
}

.Department_Data_Tiles {
  width: 45%;
  height: 150px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.NumbersOfEmployees {
  width: 100%;
  height: 50px;
  display: flex;
}

.NumbersOfEmployees h1 {
  color: #09355c;
  font-weight: 700;
  font-size: 24px;
  margin: auto;
}

.All_Emp_Data {
  width: 100%;
  height: calc(100% - 91px);
}

.Table_Container {
  width: 80%;
  height: 32%;
  margin: auto;
  display: flex;
}

.Table_Heading {
  width: 65%;
  height: 100%;
  display: flex;
}

.Table_Heading p {
  color: #787878;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
  margin-left: 0;
}

.Table_1 {
  width: 5%;
  height: 100%;
  display: flex;
}

.Table_1 p {
  color: #787878;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
  margin-left: 0;
}

.Table_2 {
  width: 30%;
  height: 100%;
  display: flex;
}

.Table_2 p {
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 700;
  margin: auto;
}

.Department_Heading {
  width: 80%;
  height: 40px;
  margin: auto;
  display: flex;
  border-top: 1px solid #787878;
}

.Department_Heading h1 {
  color: #565656;
  font-weight: 600;
  font-size: 12px;
  margin: auto;
  margin-top: 8px;
}

.Historical_Attendance_report {
  width: 100%;
  height: 31%;
  margin: auto;
  margin-bottom: 0;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.Historical_Attendance_Heading {
  width: 100%;
  height: 50px;
  display: flex;
}

.Historical_Attendance_Heading h1 {
  color: #2e2e2e;
  font-weight: 600;
  text-align: left;
  font-size: 16px;
  line-height: 1.7;
}

.Historical_Attendance_Input {
  width: 90%;
  height: calc(100% - 160px);
  margin: auto;
  display: flex;
}

.ExportData {
  width: 45%;
  height: 44px;
  margin: auto;
  margin-left: 0;
}

.ExportData_A {
  width: 45%;
  height: 44px;
  margin: auto;
  margin-right: 0;
}

.InputFielt {
  width: calc(100% - 2px);
  height: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.Input {
  width: 94%;
  height: 60%;
  margin: auto;
  position: relative;
  padding-top: 12px;
}

.inputText {
  font-size: 20px;
  width: 100%;
  height: 100%;
}

.floating_label {
  position: absolute;
  pointer-events: none;
  left: 0px;
  padding: 0px 5px;
  top: 13px;
  color: #d9d9d9;
  transition: 0.2s ease all;
  font-size: 17px;
  font-weight: 500;
}

.Input p {
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  cursor: pointer;
}

input:focus~.floating_label,
input:not(:focus):valid~.floating_label {
  color: #787878;
  top: -10px;
  font-weight: 600;
  font-size: 14px;
  background-color: white;
  padding: 0px 5px;
}

input[type="date"] {
  width: 99%;
  padding: auto;
  font-size: 18px;
  color: #363636;
  font-weight: 700;
  display: inline-block;
  border: none;
  box-sizing: border-box;
  cursor: text;
  background-color: white;
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
}

input.ng-touched.ng-invalid {
  border-bottom: 1px solid red;
}

input.ng-touched.ng-valid {
  border-bottom: 1px solid #1a2d4a;
}

.ExportButtom {
  width: 90%;
  height: 60px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.ExportButtom button {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  margin-top: 10px;
  border: none;
  background-color: #0a3b66;
  border-radius: 4px;
  padding: 10px 30px;
  cursor: pointer;
}

.ExportContent {
  width: 90%;
  height: 50px;
  margin: auto;
}

.ExportContent p {
  color: #787878;
  font-weight: 500;
  font-size: 12px;
  margin: auto;
  text-align: center;
}

.Export_Attendance_Report_Container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.Export_Attendance_Report {
  width: 78%;
  height: 75%;
  margin: auto;
  background-color: white;
}

.ListOfAllAtendanceData {
  width: 94%;
  height: calc(90% - 56px);
  margin: auto;
  margin-top: 2.4%;
}

.Button_Container {
  width: 94%;
  height: 56px;
  margin: auto;
}

.Export_Data_Heading {
  width: 100%;
  height: 48px;
  display: flex;
  background-color: #f1f1f1;
}

.Employee_Name {
  width: 16%;
  height: 100%;
  display: flex;
}

.Employee_Name p {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  margin-left: 18px;
}

.Emp_ID {
  width: 12.3%;
  height: 100%;
  display: flex;
  margin: auto;
}

.Emp_ID p {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  margin-left: 18px;
}

.Snippets {
  width: 10%;
  height: 100%;
  display: flex;
  margin: auto;
}

.Snippets p {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
}

.Export_Data_Content {
  width: 100%;
  height: calc(100% - 48px);
  overflow-y: scroll;
}

.AttendanceList {
  width: 100%;
  height: 56px;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
}

.Employee_Name_B {
  width: 16%;
  height: 100%;
  display: flex;
}

.Employee_Name_B p {
  color: #787878;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  margin-left: 18px;
}

.Emp_ID_B {
  width: 12.3%;
  height: 100%;
  display: flex;
  margin: auto;
}

.Emp_ID_B p {
  color: #787878;
  font-weight: 600;
  font-size: 14px;
  margin: auto;
  margin-left: 18px;
}

.Snippets_B {
  width: 10%;
  height: 100%;
  display: flex;
  margin: auto;
}

.Snippets_B img {
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 6px;
}

.Export_Data_Button {
  width: 100%;
  height: 56px;
  display: flex;
}

.SubmitPanel {
  width: 50%;
  height: 100%;
  display: flex;
}

.SubmitPanel p {
  color: #09355c;
  font-weight: 600;
  font-size: 18px;
  margin: auto;
  margin-left: 60px;
  margin-bottom: 10px;
  cursor: pointer;
}

.SubmitPanel button {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  margin-bottom: 0;
  margin-right: 60px;
  border: none;
  background-color: #0a3b66;
  border-radius: 4px;
  padding: 10px 40px;
  cursor: pointer;
}

.Loader_Container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.Loader_Container img {
  width: 80px;
  margin: auto;
}

.AccountStatus {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.Expire_Content_Container {
  width: 440px;
  height: 280px;
  margin: auto;
  border-radius: 6px;
  background-color: white;
}

.ExpiredIcon {
  width: 100%;
  height: 95px;
  display: flex;
}

.ExpiredIcon img {
  width: auto;
  margin: auto;
  margin-bottom: 10px;
}

.ExpiredContent {
  width: 90%;
  height: calc(100% - 95px);
  margin: auto;
}

.ExpiredContent h1 {
  color: #eb0100;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin: auto;
}

.ExpiredContent h2 {
  color: #2e2e2e;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  line-height: 2.5;
}

.ExpiredContent p {
  color: #2e2e2e;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  margin: auto;
}

.ExpiredContent p span {
  color: #2e2e2e;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

#mobileAtt {
  display: none;
}

/* #DesktopAtt {
  display: block;
} */


@media screen and (max-width: 1473px) {
  .lower_filter_dashboard button {
    margin: 2px 1px;
   
  }
 
}
/* @media screen and (max-width: 1366px) { */
 

   
    .dashboardChildUi{
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: scroll !important;
    }
    .dashboardChildUi::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    
    .dashboardChildUi::-webkit-scrollbar-track {
      background: transparent;
    }
    
    .dashboardChildUi::-webkit-scrollbar-thumb {
      background-color: #bcbcbc;
      border-radius: 7px;
    }

 /* } */
@media screen and (max-width: 1351px) {
 .lower_filter_dashboard button {
  flex: 1;                   
  border: 0;
  outline: 0;
  padding: 2px 10px ;             
  background: #ffffff;
  border-radius: 5px;
  margin: 0 5px;            
  font-size: small;
}
}

@media screen and (max-height: 768px) {
  
.upperSection{
  flex:2;
  }
  .upperrest{
  flex:3;
  margin: 10px 0;
  }
  .lastOne{
  flex:4;
  }

  .CurrentDate_Attendance_Heading {
    width: 100%;
    height:20px !important;
  }

 }











/* Container for the select box */
.custom-select-container {
  display: flex;
  flex-direction: column;
  width: 150px;
  margin: 10px 0;
}

.custom-select-container label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

/* Custom style for the select box */
.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  width: 100%;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%27http%3A//www.w3.org/2000/svg%27%20viewBox%3D%270%200%204%205%27%3E%3Cpath%20fill%3D%27%23333%27%20d%3D%27M2%200L0%202h4L2%200zM2%205L0%203h4L2%205z%27/%3E%3C/svg%3E");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  cursor: pointer;
}

/* Custom hover and focus effects */
.custom-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.custom-select:hover {
  border-color: #007bff;
}

.notFoundCount {
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}