.skeleton-container-liveTracker {
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  overflow-y: auto;
}

.skeleton-container-liveTracker::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.skeleton-container-liveTracker::-webkit-scrollbar-track {
  background: transparent;
}

.skeleton-container-liveTracker::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.skeleton-detail-liveTracker {
  border-radius: 8px;
  height: fit-content;
  border: 1px solid #eeeeee;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 384px;
  margin: 20px 0;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: shimmer_liveTracker 5s infinite;
}

@keyframes shimmer_liveTracker {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
