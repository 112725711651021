
.attendanceTable .list table {
    width: 100%;
    border-collapse: collapse;

}

.tableAttendanceHeading {
    background-color: var(--lower-use);
    height: 56px;
    border: 0;
    outline: 0;
    text-align: left;

}

.tableAttendanceHeading th,
td {
    padding: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.29px;
    text-align: left;


}

.tableDataName {
    color: #787878;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.29px;
    text-align: left;
    
}

.attendanceTable .list table tr {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    height: 56px;
}

