.mesage_notifications{
    margin: 20px 0;
}
.heading_notifications{
font-size: 16px;
font-weight: 700;
line-height: 19.36px;
}
.mesage_notifications{
    margin: 20px 0;
}
.message_child_notifications{
    margin: 20px 0;
    background: #F8F8F8;
border-radius: 10px;
padding: 20px 30px;
}
.date_notificat{
    display: flex;
    justify-content: end;
    align-items: center;
}

.date_notificat span{
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: right;

}



  
 
  .shimmer_notify {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

  }
  .item_notify_top{
    margin-bottom: 10px;
    width: 200px;
    height: 25px;
    border-radius: 10px;
    background-image: linear-gradient(to right,
    #ffffff 0%,
    #f0f0f0 50%,
    #dcdcdc 100%
  );
  animation: shimmer_notify_item_notify_top 10s infinite linear;
  }
  .shimmer-item_notify {
    width: 100%;
    height: 80px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-image: linear-gradient(to right,
    #ffffff 0%,
    #f0f0f0 50%,
    #dcdcdc 100%
  );
  animation: shimmer_notify 15s infinite linear;
  }

  @keyframes shimmer_notify {
    0% {
      background-position: -1000px 0;
    }
  
    100% {
      background-position: 1000px 0;
    }
  }

  @keyframes shimmer_notify_item_notify_top {
    0% {
      background-position: -1000px 0;
    }
  
    100% {
      background-position: 1000px 0;
    }
  }