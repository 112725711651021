.ant-picker-calendar {
    background-color: #f0f0f0; 
}

.ant-picker-calendar .ant-picker-calendar-header {
    background-color:#0A3B66 !important; 
    color: white;               
}

.ant-picker-calendar .ant-picker-calendar-day {
    color: #333;                
}

.ant-picker-calendar .ant-picker-calendar-day:hover {
    background-color:#0A3B66 !important; 
    color: white;               
}

.ant-picker-calendar .ant-picker-calendar-day-selected {
    background-color:#0A3B66 !important;
    color: white;             
}