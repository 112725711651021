.reports {
  width: 100%;
  height: 10%;
}

.heading_Att_reports {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  margin: 10px 0;
}

.heading_Att_reports .left_side {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading_Att_reports .left_side .one {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#two {
  width: 401px;
  height: 49px !important;
  padding: 0 10px !important;
  margin: 0 20px;
  border-radius: 8px;

  background-color: var(--tertiary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}


#two button {
  width: 189px !important;
  height: 36px !important;
  border-radius: 6px !important;
  /* background-color: var(--btn-color); */
  /* color: var(--secondary-color); */
  border: 0;
  outline: 0;
  cursor: pointer;
}

#activeTab {
  background-color: var(--btn-color) !important;
  color: var(--secondary-color) !important;
}
.liveTrackerExport{
  width: 300px;
  height: 49px !important;
  padding: 0 10px !important;
  margin: 0 20px;
  border-radius: 8px;
  background-color: var(--tertiary-color);
  background-color: var(--tertiary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.liveTrackerExport button{
  width: 189px !important;
  height: 36px !important;
  border-radius: 6px !important;
  padding: 0 10px;
  border: 0;
  outline: 0;
  cursor: pointer;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
 
}
.heading_Att_reports .left_side #two button span {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
}

.heading_Att_reports .left_side .one h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
}

.heading_Att_reports .left_side .one p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 900;
  line-height: 16.94px;
}

.right_side {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_side .searchBox {
  margin: 0 20px;
}

.searchBox input {
  width: 220px;
  height: 40px;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px;
  padding: 0 40px 0 20px;
  font-weight: 400;
  font-size: 16px;
  background-image: var(--search-icon);
  background-size: 20px 20px;
  background-position: right 10px center;
  background-repeat: no-repeat;
}

.list_of_attendance {
  height: 90%;
  width: 100%;
}

.list_of_data {
  height: 100%;
  width: 100%;
}
.list_of_dataLoading {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.attendanceTablePic{
  width: 40px;
  height: 42px;
  position: relative;
  cursor: pointer;
}
.overlayAttendanceTablePic{

}



.overlayAttendanceTablePic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.overlayAttendanceTablePic .zoom-icon {
  font-size: 2rem;
  color: white;
}

.attendanceTablePic:hover .overlayAttendanceTablePic {
  opacity: 1;
}

.list_of_dataLoading img {
  width: 80px;
}

.attendanceTable {
  margin: 2px auto;
  width: 98%;
  height: 95%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.list {
  width: 100%;
  height: 85%;
  overflow-y: scroll;
}
.list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.list::-webkit-scrollbar-track {
  background: transparent;
}

.list::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 7px;
}

.button_download {
  width: 98%;
  margin: auto;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.pagination {
  min-width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.leftPagination {
  flex: 2;
}
.rightPagination {
  flex: 10;
  margin: 0 20px;
}
.boxOfPagination {
  height: 55px;
  width: 77px;
  box-shadow: 0px 0px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.boxOfPaginationRight{
  height: 55px;
  width: max-content;
  box-shadow: 0px 0px 4px 0px #00000040;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}
.boxOfPagination span:nth-child(1) {
  color: #565656;
}
.boxOfPagination span:nth-child(2) {
  color: black;
  font-weight: 900;
  margin-top: 5px;
}
.dropDownPagination {
  position: absolute;
  width: 77px;
  box-shadow: 0px 0px 4px 0px #00000040;
  background-color: white;
  bottom: 125px;
}
.skipPage {
  height: 35px;
  width: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.skipPage:hover{
  background-color: whitesmoke;
}
.skipPageMargin {
  padding: 10px 0;
}

table td,
th {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow content */
}
.button_download .downloadBtn {
  width: 160px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--btn-color);
  color: var(--secondary-color);
  border: 0;
  outline: 0;
  cursor: pointer;
}
.Refresh{
  width: 160px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--btn-color);
  color: var(--secondary-color);
  border: 0;
  outline: 0;
  cursor: pointer;
}
.button_download .downloadBtn span {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
}
.datePickerReportSections {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1542px) {
  .heading_Att_reports {
    width: 100%;
    max-width: 1350px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    white-space: nowrap;
  }

  .heading_Att_reports .left_side,
  .heading_Att_reports .right_side {
    display: inline-flex;
    flex-shrink: 0;
  }
  .heading_Att_reports {
    max-width: 1350px; /* Set a maximum width */
  }
  .heading_Att_reports::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .heading_Att_reports::-webkit-scrollbar-track {
    background: transparent;
  }

  .heading_Att_reports::-webkit-scrollbar-thumb {
    background-color: #bcbcbc;
    border-radius: 7px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1080px) {
  #two {
    min-width: 311px;
    height: 49px !important;
    padding: 0 10px !important;
    margin: 0 20px;
    border-radius: 8px;

    background-color: var(--tertiary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #two button {
    width: 159px !important;
    height: 36px !important;
    border-radius: 6px !important;
    /* background-color: var(--btn-color); */
    /* color: var(--secondary-color); */
    border: 0;
    outline: 0;
    cursor: pointer;
  }
}
