.sidebar {
  height: 100%;
  width: 100%;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.lastBottomSidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.lastBottomSidebar span {
  margin: 0 5px;
}
.lastBottomSidebar span:nth-child(2) {
  color: white;
  font-weight: 600;
}
.lastBottomSidebar span:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff66;
}

.version_ref {
  margin-top: 15px;
}
.version_ref span:nth-child(1) {
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-weight: 500;
}
.version_ref span:nth-child(2) {
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-weight: 500;
}

.version_ref span:nth-child(3) {
  color: white;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 2px solid white;
}
.version_ref span:nth-child(3) a {
    color: white;
    text-decoration:none;
  }
.sideNavContainer {
  width: 100%;
  display: flex;
  margin: 20px 0;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}

.sidebarWrapper {
  width: 100%;
  height: 48px;
  padding: 1px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.bgApplied {
  width: 100%;
  /* padding: 10px 15px; */
  border-radius: 24px;
  background-color: var(--dark-btn);
}

.sidebarNavIcons {
  display: flex;
  align-items: center;
}

.sidebarNavIcons img {
  width: 24px;
  height: 24px;
}

.sidebarIconsName {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: var(--secondary-color);
  margin: 0 30px;
}
